<template>
  <div>
    <b-row class="auth-inner m-0 d-flex align-items-center justify-content-center">
      <b-col md="12">
        <b-link>
          <b-img-lazy :src="appLogoImage" alt="logo" width="190" class="mt-1" />
        </b-link>
      </b-col>

      <!-- Left Text-->
      <b-col
        md="8"
        class="d-flex align-items-center justify-content-center pt-3 relative"
      >
        <b-card class="w-100">
          <b-card-header
            class="d-flex justify-content-between align-items-center pt-75 pb-25"
          >
            <h2 class="mb-0">Registro de usuario</h2>
            <b-link :to="{ name: 'auth-login' }">
              <b-button variant="outline-primary"> Iniciar sesión </b-button>
            </b-link>
          </b-card-header>
          <b-overlay
            :show="loadingRegister"
            rounded
            responsive
            variant="transparent"
            opacity="0.33"
            class="table-responsive"
            blur="2px"
          >
            <form-wizard
              class="w-100 primary-color"
              :title="null"
              :subtitle="null"
              finish-button-text="Guardar"
              back-button-text="Atrás"
              next-button-text="Siguiente"
              @on-complete="registerMethod"
              ref="formWizard"
              color="#121441"
            >
              <!-- Colegios-->
              <tab-content title="Colegio" :before-change="colegiosRules">
                <validation-observer ref="colegiosRules" tag="form">
                  <b-row class="d-flex align-items-center justify-content-center">
                    <b-col md="6" v-for="(tr, indexTr) in schoolsOptions" :key="indexTr">
                      <!-- select circle avatar colegio -->
                      <div
                        class="d-flex flex-column align-items-center justify-content-center hoverEfect"
                        @click="selectSchool(tr.id)"
                      >
                        <b-avatar
                          :class="[
                            `${tr.id == formData.school_id ? 'imgLogoSelect' : ''}`,
                          ]"
                          :src="tr.logo"
                          alt="logos"
                          size="90px"
                          rounded
                        />
                        <h3>{{ tr.name }}</h3>
                      </div>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- Información Personal -->
              <tab-content title="Información Personal" :before-change="informationRules">
                <validation-observer ref="informationRules" tag="form">
                  <b-row>
                    <b-col cols="12" class="mb-2">
                      <h5 class="mb-0">Informacion personal</h5>
                    </b-col>
                    <b-col md="4" lg="3">
                      <b-form-group label="Nombres" label-for="nombres">
                        <validation-provider
                          #default="{ errors }"
                          name="nombres"
                          rules="required|alpha-spaces|min:3|max:50"
                        >
                          <b-form-input
                            id="nombres"
                            v-model="formData.name"
                            :state="errors.length > 0 ? false : null"
                            @keypress="filterString"
                            maxlength="15"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3">
                      <b-form-group label="Apellidos" label-for="last_name">
                        <validation-provider
                          #default="{ errors }"
                          name="apellido"
                          rules="required|alpha-spaces|min:3|max:50"
                        >
                          <b-form-input
                            v-model="formData.last_name"
                            :state="errors.length > 0 ? false : null"
                            @keypress="filterString"
                            maxlength="15"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3">
                      <b-form-group label="Tipo de usuario" label-for="typeUser">
                        <validation-provider
                          #default="{ errors }"
                          name="tipo de usuario"
                          rules="required"
                        >
                          <v-select
                            id="typeUser"
                            v-model="formData.typeUser"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="text"
                            :reduce="(typeUser) => typeUser.value"
                            :options="typeUserOptions"
                            :state="errors.length > 0 ? false : null"
                            :clearable="false"
                            :searchable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3">
                        <b-form-group label="Cedula de identidad" label-for="id_card">
                        <validation-provider
                          #default="{ errors }"
                          name="cedula de identidad"
                          rules=""
                        >
                          <b-form-input
                          id="id_card"
                          v-model="formData.id_card"
                          :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3">
                      <b-form-group label="Fecha de nacimiento" label-for="birthday">
                        <validation-provider
                          #default="{ errors }"
                          name="fecha de nacimiento"
                          rules="required"
                        >
                          <date-picker
                            v-model="formData.birthday"
                            type="date"
                            :default-value="new Date()"
                            :disabled-date="disabledBeforeFourYears"
                          ></date-picker>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3" v-if="formData.typeUser == 'student'">
                      <b-form-group label="Grado" label-for="grado">
                        <validation-provider
                          #default="{ errors }"
                          name="grado"
                          rules="required"
                        >
                          <b-form-input
                            id="grado"
                            v-model="formData.grado"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3" v-if="formData.typeUser == 'student'">
                      <b-form-group label="Sección" label-for="section">
                        <validation-provider
                          #default="{ errors }"
                          name="section"
                          rules="required"
                        >
                          <b-form-input
                            id="section"
                            v-model="formData.section"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3" v-if="formData.typeUser == 'student'">
                      <b-form-group label="Aula" label-for="aula">
                        <validation-provider
                          #default="{ errors }"
                          name="aula"
                          rules="required"
                        >
                          <b-form-input
                            id="aula"
                            v-model="formData.aula"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" lg="3">
                      <b-form-group label="Teléfono" label-for="name">
                        <validation-provider
                          #default="{ errors }"
                          name="telefono"
                          rules="required"
                        >
                          <!-- <b-form-input
                            v-model="formData.phone"
                            type="number"
                            :state="errors.length > 0 ? false : null"/> -->

                          <vue-tel-input
                            v-model="formData.phone"
                            @validate="validateNumber"
                            placeholder="+X 999 999 999"
                            :defaultCountry="'VE'"
                            maxlength="20"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- USUARIO-->
              <tab-content title="Usuario" :before-change="usuarioRules">
                <validation-observer ref="usuarioRules" tag="form">
                  <b-row>
                    <b-col cols="12" class="mb-2">
                      <h5 class="mb-0">Informacion Para iniciar sesión</h5>
                    </b-col>
                    <b-col md="6" lg="4">
                      <b-form-group label="Correo electronico" label-for="email">
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="email"
                            v-model="formData.email"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="contraseña"
                          vid="password"
                          rules="required"
                        >
                          <label>Contraseña</label>
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="register-password"
                              v-model="formData.password"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              :state="errors.length > 0 ? false : null"
                              name="register-password"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="contraseña"
                          vid="c_password"
                          rules="required|confirmed:password"
                        >
                          <label>Confirmar contraseña</label>
                          <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              id="register-c_password"
                              v-model="formData.c_password"
                              class="form-control-merge"
                              :type="passwordFieldType"
                              :state="errors.length > 0 ? false : null"
                              name="register-c_password"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
            </form-wizard>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImgLazy,
  BAvatar,
  BCard,
  BCardHeader,
  BBadge,
  BCardText,
  BCardTitle,
  BOverlay,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, email, alphaSpaces, min, max } from "@core/utils/validations/validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import vSelect from "vue-select";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
localize("es", es);
export default {
  components: {
    BRow,
    BImgLazy,
    BAvatar,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCard,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardHeader,
    BBadge,
    // validations
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    FormWizard,
    TabContent,
    BFormInvalidFeedback,
    vSelect,
    DatePicker,
  },

  mixins: [togglePasswordVisibility],
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      isValidateNumber: false,
      schoolsOptions: [],
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      required,
      email,
      alphaSpaces,
      min,
      max,
      loadingRegister: false,
      formData: {
        birthday: new Date(
          new Date().getFullYear() - 4,
          new Date().getMonth(),
          new Date().getDate()
        ),
        school_id: null,
      },
      typeUserOptions: [
        {
          value: "student",
          text: "Estudiante",
        },
        {
          value: "representative",
          text: "Representante",
        },
        {
          value: "teacher",
          text: "Profesor",
        },
      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    filterString(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (
        (keyCode >= 48 && keyCode <= 57) ||  
        keyCode === 33 || keyCode === 64 || keyCode === 35 ||  
        keyCode === 36 || keyCode === 37 || keyCode === 94 ||
        keyCode === 38 || keyCode === 42
      ) {
        event.preventDefault(); 
      }
    },
    validateNumber(value) {
      console.log(value.valid);
      this.isValidateNumber = value.valid;
    },
    disabledBeforeFourYears(date) {
      const today = new Date();
      const fourYearsAgo = new Date(
        today.getFullYear() - 4,
        today.getMonth(),
        today.getDate()
      );
      return date >= fourYearsAgo;
    },
    colegiosRules() {
      return new Promise((resolve, reject) => {
        this.$refs.colegiosRules.validate().then((success) => {
          if (success && this.formData.school_id !== null) {
            resolve(true);
          } else {
            if (this.formData.school_id == null) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Error",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: "Seleccione el colegio donde estudia",
                },
              });
            }
            reject();
          }
        });
      });
    },
    informationRules() {
      return new Promise((resolve, reject) => {
        this.$refs.informationRules.validate().then((success) => {
          // Validar que el número de teléfono sea válido
          if (!this.isValidateNumber) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "El número de teléfono no es válido",
              },
            });

            reject();
            return;
          }

          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    usuarioRules() {
      return new Promise((resolve, reject) => {
        this.$refs.usuarioRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async registerMethod() {
      try {
        this.loadingRegister = true;
        await useJwt.register(this.formData);
        await this.$store.dispatch("user/login", {
          email: this.formData.email,
          password: this.formData.password,
        });
        window.location.href = "/";
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: error.response.data.message,
          },
        });
      } finally {
        this.loadingRegister = false;
      }
    },
    async getSchools() {
      try {
        this.loadingRegister = true;
        const response = await this.$store.dispatch("schools/index");
        this.schoolsOptions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingRegister = false;
      }
    },
    selectSchool(id) {
      this.formData.school_id = id;
      // next step
      this.$refs.formWizard.nextTab();
    },
  },
  mounted() {
    this.getSchools();
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.hoverEfect {
  &:hover {
    // zoon
    transform: scale(1.1);
    // transition
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .imgLogoSelect {
    // border select
    border: 2px solid rgb(238, 57, 38);
  }
}
.mx-datepicker {
  width: 100%;
}
</style>
